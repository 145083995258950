<script>
import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  components: {
  },
  data() {
    return {
      saveAllTableColum: [],
      formConfig: {},
      searchFormData: {},
    };
  },
  mounted() {
    this.getConfigList('dealer_quota_report_list').then((res) => {
      this.saveAllTableColum = res;
    });
  },
  methods: {
    // 判断是否有‘productOrSeries’字段
    hasProductOrSeries() {
      const index = this.searchList.findIndex(
        (item) => item.field === 'productOrSeries',
      );
      if (index > -1) {
        return true;
      }
      return false;
    },
    // 获取列表数据前置函数
    beforeGetList() {
      if (this.hasProductOrSeries()) {
        this.formData.productOrSeries = this.searchFormData.productOrSeries || null;
        if (this.saveAllTableColum.length > 0) {
          this.configs.columns = this.saveAllTableColum.filter((rowData) => {
            if (this.searchFormData.productOrSeries === 'series') {
              if (['productCode', 'productName', 'baseUnit', 'applyAmount', 'quotaUsedAmount', 'usedAmount', 'addAmount', 'reduceAmount', 'advanceAmount', 'balanceAmount'].includes(rowData.field)) {
                return false;
              }
            }
            if (this.searchFormData.productOrSeries === 'product') {
              if (['seriesCode', 'seriesName', 'applyMoney', 'usedMoney', 'quotaUsedMoney', 'addMoney', 'reduceMoney', 'advanceMoney', 'balanceMoney'].includes(rowData.field)) {
                return false;
              }
            }
            return true;
          });
          this.configs.columns = this.configs.columns.map((v) => this.setColumn(v));
        }
      } else if (this.searchFormData.productOrSeries) {
        delete this.searchFormData.productOrSeries;
      }
      return true;
    },
    setColumn(v) {
      const rowData = v;
      if (this.searchFormData.productOrSeries === 'product') {
        if (['seriesCode', 'seriesName', 'applyMoney', 'quotaUsedMoney', 'usedMoney', 'addMoney', 'reduceMoney', 'advanceMoney', 'balanceMoney'].includes(rowData.field)) {
          rowData.visible = false;
        }
        if (['productCode', 'productName', 'baseUnit', 'applyAmount', 'quotaUsedAmount', 'usedAmount', 'addAmount', 'reduceAmount', 'advanceAmount', 'balanceAmount'].includes(rowData.field)) {
          rowData.visible = true;
        }
      }
      if (this.searchFormData.productOrSeries === 'series') {
        if (['productCode', 'productName', 'baseUnit', 'applyAmount', 'quotaUsedAmount', 'usedAmount', 'addAmount', 'reduceAmount', 'advanceAmount', 'balanceAmount'].includes(rowData.field)) {
          rowData.visible = false;
        }
        if (['seriesCode', 'seriesName', 'applyMoney', 'quotaUsedMoney', 'usedMoney', 'addMoney', 'reduceMoney', 'advanceMoney', 'balanceMoney'].includes(rowData.field)) {
          rowData.visible = true;
        }
      }
      return rowData;
    },
  },
};
</script>
